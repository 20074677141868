import React from 'react';
import 'handsontable/dist/handsontable.full.min.css';

import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';

registerAllModules();
/* DOM */
import ReactDOM from 'react-dom/client';
const root = ReactDOM.createRoot(document.getElementById("inject"));

/* Router */
import { BrowserRouter } from 'react-router-dom';
import Routes from "./routes";

/* Redux */
import { Provider } from 'react-redux';
import store from './reducers'

const AppStore = () => (
    <Provider store={store}>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </Provider>
);

root.render(<AppStore />);
