import React, {useEffect, useState} from 'react';
import AdvisorTable from "./table/advisor";
import EventsTable from "./table/events";
import Nav from "./nav";
import UserTable from "./table/users";
import Dashboard from "./table/dashboard";
import {tryGetAllReports} from "../middleware/getReportData";
import FunnelReport from "./reports/funnel-report";
import GraphReport from "./reports/graph-report";
import TableReport from "./reports/table-report";
import Reports from "./reports/reports";
export default function Entry() {

    return (
        <>
            <Nav />
            {window.location.href.includes("dashboard") &&  <Dashboard />}
            {window.location.href.includes("report") && window.location.search.includes("Funnel") && <FunnelReport />}
            {window.location.href.includes("report") && window.location.search.includes("Graph") && <GraphReport />}
            {window.location.href.includes("report") && window.location.search.includes("Table") && <TableReport />}
            {window.location.href.includes("reports") && <Reports />}
            {window.location.href.includes("graph") && <GraphReport />}
            {window.location.href.includes("users") && <UserTable />}
            {window.location.href.includes("advisor") && <AdvisorTable />}
            {window.location.href.includes("events") && <EventsTable />}

        </>
    )

};