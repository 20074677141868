import React, {useEffect, useState} from 'react';
import {Box, Button, Grid2, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import {tryGetAllReports} from "../../middleware/getReportData";
import {useNavigate} from "react-router-dom";
import SummaryReport from "../reports/summary-report";



export default function Dashboard(props) {
    return <SummaryReport />
}