import React, {useEffect, useState} from "react";
import {
    tryGetDataAdCampaign, tryGetDataAdCampaignOptions, tryGetDataAdCreative, tryGetDataAdCreativeOptions,
    tryGetDataAdSource, tryGetDataAdSourceOptions, tryGetTotalBySource, tryGetTotalData
} from "../../middleware/getAdData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Total from "./total";
import styled from "styled-components";
import {
    Box,
    Button,
    Divider,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Select,
    TextField
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {colors} from '../../components/colors'
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime as Datetime, DateTime} from "luxon";
import TableCell from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import {tryGetEventsAggregated, tryGetUTMS} from "../../middleware/consumerData";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {tryCreateReport, tryGetReport, trySaveReport} from "../../middleware/getReportData";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export const TableContainerStyled = styled(TableContainer)`
    .cellName {
      background-color: ${colors.action70};
      font-weight: bold;
    }

  .dateRow {
    background-color: ${colors.action70};
  }

  .data {
    background-color: ${colors.canvaSand};
  }

  .rowName {
    background-color: ${colors.canvaSand};
  }

  .activeDate {
    background-color: ${colors.secondary100};
  }
  
`

const StyledBox = styled(Box)`
  min-width: 120px;
  margin:10px 0;
`
export const StyledTable = styled(Table)`
  margin-bottom: 20px;
  overflow-x: scroll;
`
export const StyledTableCell = styled(TableCell)`
  width: 4rem
`
export default function SummaryReport(props) {

    const [id, setId] = useState(props.id ? props.id : 0)
    const [optionsAdSource, setOptionsAdSource] = useState([
        {
            value :'Google',
            label:'Google'
        }
    ])
    const [optionsAdCampaign, setOptionsAdCampaign] = useState([])
    const [optionsAdCreative, setOptionsAdCreative] = useState([])
    const [ad, setAdSource] = useState("")
    const [adCampaign, setAdCampaign] = useState('')
    const [adCreative, setAdCreative] = useState('')
    const [currentTotal, setCurrentTotal] = useState({})
    const [currentData, setCurrentData] = useState({})
    const [startDate, setStartDate] = useState(Datetime.now())
    const [endDate, setEndDate] = useState(Datetime.now())
    const [events, setEvents] = useState({})
    const [currentCreative, setCurrentCreative] = useState({})
    const drawerWidth = 240;
    const [locale, setLocale] = React.useState('en-us');

    const [dateType, setDateType] = useState('Daily')
    const [utmOptions, setUtmOptions] = useState({
        utm_content: ['test_content'],
        utm_term: ['test_term'],
        utm_source: ['test_source'],
        utm_medium: ['test_medium'],
        utm_campaign: ['test_campaign']
    })

    const [utmTerm, setUTMTerm] = useState([])
    const [utmContent, setUTMContent] = useState([])
    const [utmMedium, setUTMMedium] = useState(['test_medium'])
    const [utmSource, setUTMSource] = useState([])
    const [utmCampaign, setUTMCampaign] = useState([])

    const [isLoading, setLoading] = useState(true)
    const [reportName, setReportName] = useState("")

    const handleUTMContent = (event) => {
        const {
            target: { value },
        } = event;
        setUTMContent(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleUTMTerm = (event) => {
        const {
            target: { value },
        } = event;
        setUTMTerm(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleUTMMedium = (event) => {
        const {
            target: { value },
        } = event;
        setUTMMedium(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleUTMCampaign = (event) => {
        const {
            target: { value },
        } = event;
        setUTMCampaign(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleUTMSource = (event) => {
        const {
            target: { value },
        } = event;
        setUTMSource(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleAdCreative = (event) => {
        const {
            target: { value },
        } = event;
        setAdCreative(value);
    }

    const handleAdCampaign = (event) => {
        const {
            target: { value },
        } = event;
        setAdCampaign(value);
    }

    const handleAdSource = (event) => {
        const {
            target: { value },
        } = event;
        setAdSource(value);
    }


    // Total data
    // Ad source options
    useEffect( () => {
        const init = async () => {
            let total = await tryGetTotalData(endDate.toISODate(), endDate.toISODate(), dateType)
            console.log("total", total)
            setCurrentTotal(total)
            setUtmOptions(await tryGetUTMS())
            let current = await tryGetTotalBySource(startDate.toISODate(), endDate.toISODate(), dateType)
            setCurrentData(current)
            console.log("current",current)
            let events = await tryGetEventsAggregated(startDate.toISODate(), endDate.toISODate(), dateType)
            setEvents(events)
            console.log("events", events)
            setLoading(false)
            setOptionsAdSource(await tryGetDataAdSourceOptions())
            if (id !== 0) {
                let report = await tryGetReport(id)
                setStartDate(report.start_date)
                setEndDate(report.end_date)
                setUTMTerm(report.event_names.utm_term)
                setUTMSource(report.event_names.utm_source)
                setUTMMedium(report.event_names.utm_medium)
                setUTMContent(report.event_names.utm_content)
                setUTMCampaign(report.event_names.utm_campaign)
                setDateType(report.frequency)
                setReportName(report.report_name)

            }
        }
        init()
    },[])

    // Ad source updates
    // Ad campaign options
    useEffect(() => {
        const update = async () => {
            if (ad !== "") {
                setOptionsAdCampaign(await tryGetDataAdCampaignOptions(ad))
            }

            if (adCampaign !== "") {
                setOptionsAdCreative(await tryGetDataAdCreativeOptions(adCampaign))
            }
        }
        update()
    },[ad, adCampaign])

    useEffect(() => {
        const update = async () => {
            await sortByDateType()
        }
        update()
    },[startDate,endDate,dateType, utmMedium, utmSource, utmCampaign, utmContent, utmTerm])

    useEffect(() => {
        const update = async () => {
            if (adCreative !== '') {
                setCurrentCreative(currentData)
            }

        }
        update()
    }, [currentData])




    // Sorts data by either daily, weekly, or last 6 months
    const sortByDateType = async () => {
        let data;
        let total;
        let start = startDate.toISODate()
        let end = endDate.toISODate()
        if (ad !== "" && adCampaign === "" && adCreative === "") {
            data = await tryGetDataAdSource(ad, start, end, dateType)
            setCurrentData(data)
        }
        else if (adCampaign !== "" && adCreative === "") {
            data = await tryGetDataAdCampaign(adCampaign, start, end,  dateType)
            setCurrentData(data)
        }
        else if (adCreative !== "") {
            data = await tryGetDataAdCreative(adCreative, start, end,  dateType)
            setCurrentData(data)

        }
        total = await tryGetTotalData(start, end,  dateType)
        let events = await tryGetEventsAggregated(start, end, dateType)
        setEvents(events)
        setCurrentTotal(total)

         if (dateType === 'YTD') {
            const ytd = DateTime.now().startOf('year').toISODate();
             if (ad !== "" && adCampaign === "" && adCreative === "") {
                data = await tryGetDataAdSource(ad, ytd, end, 'Monthly')
                setCurrentData(data)
            }
            else if (adCampaign !== "" && adCreative === "") {
                data = await tryGetDataAdCampaign(adCampaign, ytd, end, 'Monthly')
                setCurrentData(data)
            }
            else if (adCreative !== "") {
                data = await tryGetDataAdCreative(adCreative, ytd, end, 'Monthly')
                setCurrentData(data)

            }
            total = await tryGetTotalData(ytd, end, 'Monthly')
            let events = await tryGetEventsAggregated(ytd, end, 'Monthly')
            setEvents(events)
            setCurrentTotal(total)
        }



    }

    const createReport = async () => {
        const id = await tryCreateReport({"report_type": "Summary",
            "display_type" : "Table",
            "start_date" : startDate.toISODate(),
            "end_date" : endDate.toISODate(),
            "event_names": [("utm_medium", utmMedium), ("utm_source", utmSource), ("utm_campaign", utmCampaign), ("utm_content",utmContent) ("utm_term", utmTerm),
                ("ad_source", ad), ("ad_campaign", adCampaign), ("ad_creative", adCreative)],
            "frequency": dateType,
            "report_name":reportName})
    }

    const saveReport = async () => {
        await trySaveReport(id, {"report_type": "Summary",
            "display_type" : "Table",
            "start_date" : startDate.toISODate(),
            "end_date" : endDate.toISODate(),
            "event_names": [("utm_medium", utmMedium), ("utm_source", utmSource), ("utm_campaign", utmCampaign), ("utm_content",utmContent) ("utm_term", utmTerm),
                ("ad_source", ad), ("ad_campaign", adCampaign), ("ad_creative", adCreative)],
            "frequency": dateType,
            "report_name":reportName})
    }

    const handleReport = async () => {
        if (id !== 0) {
            await saveReport()
        }  else {
            await createReport()
        }
    }



    if (isLoading) {

    } else {
        return (
        <div>
            <Box sx={{display: 'flex', width: '100%'}}>
                <CssBaseline/>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', zIndex:1,backgroundColor:"transparent"
                        },
                    }}
                >
                    <Toolbar/>
                    <Box sx={{overflow: 'auto'}}>
                        <h3>Filters</h3>
                        <List sx={{margin: '0 20px 0 20px'}}>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel>Ad Source</InputLabel>
                                    <Select
                                        value={ad}
                                        label="ad_source"
                                        onChange={handleAdSource}
                                    >
                                        {optionsAdSource.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel>Ad Campaign</InputLabel>
                                    <Select
                                        value={adCampaign}
                                        label="ad_campaign"
                                        onChange={handleAdCampaign}
                                    >
                                        {optionsAdCampaign.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel>Ad Creative</InputLabel>
                                    <Select
                                        value={adCreative}
                                        label="ad_creative"
                                        onChange={handleAdCreative}
                                    >
                                        {optionsAdCreative.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120, margin:'10px 0' }}>
                                <FormControl fullWidth>
                                    <InputLabel>UTM Medium</InputLabel>
                                    <Select
                                        multiple
                                        value={utmMedium}
                                        label="utm_medium"
                                        onChange={handleUTMMedium}
                                    >
                                        {utmOptions.utm_medium.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel>UTM Content</InputLabel>
                                    <Select
                                        multiple
                                        value={utmContent}
                                        label="utm_content"
                                        onChange={handleUTMContent}
                                    >
                                        {utmOptions.utm_content.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel>UTM Term</InputLabel>
                                <Select
                                    multiple
                                    value={utmTerm}
                                    label="utm_term"
                                    onChange={handleUTMTerm}
                                >
                                    {utmOptions.utm_term.map((u) => {
                                        return <MenuItem value={u}>{u}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel>UTM Campaign</InputLabel>
                                    <Select
                                        multiple
                                        value={utmCampaign}
                                        label="utm_campaign"
                                        onChange={handleUTMCampaign}
                                    >
                                        {utmOptions.utm_campaign.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>
                            <StyledBox sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel >UTM Source</InputLabel>
                                    <Select
                                        multiple
                                        value={utmSource}
                                        label="utm_source"
                                        onChange={handleUTMSource}
                                    >
                                        {utmOptions.utm_source.map((u) => {
                                            return <MenuItem value={u}>{u}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </StyledBox>

                        </List>
                        <Divider/>
                        <List>
                            {['Daily', 'Weekly', 'Monthly', 'YTD'].map((text, index) => (
                                <ListItem key={text} disablePadding sx = {{backgroundColor : text === dateType && colors.primary70}}>
                                    <ListItemButton onClick={() => setDateType(text)}
                                                >
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
                                <DatePicker
                                    label="Start"
                                    value={startDate}
                                    onChange={(value) => setStartDate(value)}
                                />
                                <DatePicker
                                    label="End"
                                    value={endDate}
                                    onChange={(value) => setEndDate(value)}
                                />
                                {/*<Button onClick={() => filter()}>Filter</Button>*/}

                            </LocalizationProvider>
                        </List>


                    </Box>

                </Drawer>

                <AppBar position="absolute" sx={{
                    left: drawerWidth,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: colors.primary30,
                    top:'68px',
                    height:'68px',
                    boxShadow:0,
                    display:'flex',
                    flexDirection: 'row'
                }}>
                    <TextField
                        id="outlined-basic" label="Report Name" variant="outlined"
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                        sx={{maxWidth: 200, backgroundColor: 'white'}}

                    />
                    <Button
                        key={'create'}
                        sx={{ my: 1, color: 'white', display: 'block'}}
                        onClick={handleReport}
                        sx={{maxWidth: 200, backgroundColor: 'white'}}
                    >
                        <MenuItem> Create Report</MenuItem>
                    </Button>
                </AppBar>
                <Box sx={{width: '100%'}}>
                    <Toolbar/>
                   <Total rows={currentTotal} key={"total"} name={"Total"} events={events}/>
                    {/* "Each Ad Source Data" Tables*/}
                    <TableContainerStyled>
                        {ad === "" && adCreative === "" && <h3> Please choose a data input </h3>}
                        {currentCreative.length > 0 && <Total rows={currentCreative} key={"creative"} name={adCreative}/>}
                        {ad !== "" && adCreative === "" && Object.keys(currentData).map((a, index) => {
                            return <StyledTable sx={{minWidth: '100%'}} aria-label="simple table" key={a} className={'tableStyle'}>
                                <TableHead>
                                    <TableRow className={'dateRow'}>
                                        <TableRow>
                                            <StyledTableCell className={'cellName'}>{a}</StyledTableCell>
                                        </TableRow>
                                        {currentData[a].map((row, index) => (

                                            <StyledTableCell component="th" scope="row" key={"date" + index}>
                                                {`${new Date(row.created).getMonth() + 1}/${new Date(row.created).getDate()}/${new Date(row.created).getFullYear()}`}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                                            Spend
                                        </StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"spend" + index}>
                                                ${Number(row.spend).toLocaleString('en')}
                                            </StyledTableCell>
                                        })}

                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"impressions" + index}>
                                                {Number(row.impressions).toLocaleString()}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Clicks</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            return <StyledTableCell key={"clicks" + index}>
                                                {Number(row.clicks).toLocaleString()}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>

                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" className={'rowName'} >Survey Starts</StyledTableCell>*/}
                                    {/*    {events["clientAcquisitionSurveyStart"].map((row, index) => {*/}
                                    {/*        return <StyledTableCell key={"survey" + index}>*/}
                                    {/*            {Number(row.count).toLocaleString()}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}

                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" className={'rowName'} >Email Captures</StyledTableCell>*/}
                                    {/*    {events["email"].map((row, index) => {*/}
                                    {/*        return <StyledTableCell key={"email" + index}>*/}
                                    {/*            {Number(row.count).toLocaleString()}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}


                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" className={'rowName'} >Asset Captures</StyledTableCell>*/}
                                    {/*    {events["rangeAssets"].map((row, index) => {*/}
                                    {/*        return <StyledTableCell key={"asset" + index}>*/}
                                    {/*            {Number(row.count).toLocaleString()}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}


                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" className={'rowName'} >Phone Captures</StyledTableCell>*/}
                                    {/*    {events["phone"].map((row, index) => {*/}
                                    {/*        return <StyledTableCell key={"phone" + index}>*/}
                                    {/*            {Number(row.clicks).toLocaleString()}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}

                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} >Cost / Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerImpressions = (row.spend / row.impressions).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                ${spendPerImpressions}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                                            Cost / Clicks
                                        </StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerClick = (row.spend / row.clicks).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                ${spendPerClick}
                                            </StyledTableCell>
                                        })}
                                    </TableRow>

                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >*/}
                                    {/*        Cost / Survey Start*/}
                                    {/*    </StyledTableCell>*/}
                                    {/*    {currentData[a].map((row, index) => {*/}
                                    {/*        let e = events["clientAcquisitionSurveyStart"][index] ? events["clientAcquisitionSurveyStart"][index] : 0*/}
                                    {/*        const spendPerStart = (row.spend / e).toFixed(2).toLocaleString();*/}
                                    {/*        return <StyledTableCell key={"spend" + index}>*/}
                                    {/*            ${spendPerStart}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}

                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >*/}
                                    {/*        Cost / Email Capture*/}
                                    {/*    </StyledTableCell>*/}
                                    {/*    {currentData[a].map((row, index) => {*/}
                                    {/*        let e = events["email"][index] ? events["email"][index] : 0*/}
                                    {/*        const spendPerEmail = (row.spend / e).toFixed(2).toLocaleString()*/}
                                    {/*        return <StyledTableCell key={"spend" + index}>*/}
                                    {/*            ${spendPerEmail}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}
                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >*/}
                                    {/*        Cost / Assets*/}
                                    {/*    </StyledTableCell>*/}
                                    {/*    {currentData[a].map((row, index) => {*/}
                                    {/*        let e = events["rangeAssets"][index] ? events["rangeAssets"][index] : 0*/}
                                    {/*        const spendPerAssets = (row.spend / e).toFixed(2).toLocaleString()*/}
                                    {/*        return <StyledTableCell key={"spend" + index}>*/}
                                    {/*            ${spendPerAssets}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}
                                    {/*<TableRow>*/}
                                    {/*    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >*/}
                                    {/*        Cost / Phone Capture*/}
                                    {/*    </StyledTableCell>*/}
                                    {/*    {currentData[a].map((row, index) => {*/}
                                    {/*        let e = events["phone"][index] ? events["phone"][index] : 0*/}
                                    {/*        const spendPerPhone = (row.spend / e).toFixed(2).toLocaleString()*/}
                                    {/*        return <StyledTableCell key={"spend" + index}>*/}
                                    {/*            ${spendPerPhone}*/}
                                    {/*        </StyledTableCell>*/}
                                    {/*    })}*/}
                                    {/*</TableRow>*/}
                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} ># Clicks / #
                                            Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                {clicksToImpressions}%
                                            </StyledTableCell>
                                        })}
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableCell align="left" className={'rowName'} ># Clicks / #
                                            Impressions</StyledTableCell>
                                        {currentData[a].map((row, index) => {
                                            const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                                            return <StyledTableCell key={"spend" + index}>
                                                {clicksToImpressions}%
                                            </StyledTableCell>
                                        })}
                                    </TableRow>

                                </TableBody>
                            </StyledTable>
                        })}
                    </TableContainerStyled>
                </Box>
            </Box>
        </div>


    )
    }
}