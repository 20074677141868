import {useNavigate} from "react-router-dom";
import {tryGetAllReports} from "../../middleware/getReportData";
import {Box, Grid2} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";


export default function Reports() {
    const [reports, setReports] = useState([])
    useEffect(() => {
        init();
    })

    let navigate = useNavigate();

    function handleClick(id,type) {
        if (type === "Funnel") {
            navigate(`/report/${id}?report_type=Funnel`);
        } else if (type === "Graph"){
            navigate(`/report/${id}?report_type=Graph`);
        } else if (type === "Summary") {
            navigate(`/report/${id}?report_type=Summary`)
        } else if (type === "Table") {
            navigate(`/report/${id}?report_type=Table`);
        }
    }
    const init = async () => {
        const report = await tryGetAllReports();
        setReports(report)
    }
    const imageView = (type) => {
        if (type === 'Funnel') {
            return <img  alt={"Funnel Report Preview"}/>
        } else if (type === 'Table') {
            return <img alt={"Table Report Preview"}/>
        } else if (type === "Graph") {
            return <img alt={"Graph Report Preview"} />
        } else {
            return <img alt={"Unknown Report Preview"} />
        }
    }


    return (
        <>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                {reports.map((r) => {
                    return <Paper sx={{ my: 1, mx: 'auto', p: 2 }} onClick={() => handleClick(r.report_id, r.report_type)}>
                        <Grid2 container spacing={2} wrap="nowrap">
                            <Grid2 size={8}>
                                {imageView(r.report_type)}
                            </Grid2>
                            <Grid2 size={8}>
                                <Typography noWrap>{r.report_name}</Typography>
                            </Grid2>
                        </Grid2>
                    </Paper>
                })}
            </Box>
            );
        </>
    )
}