import {axiosReq} from "../utils/axios";

const devEvents = {
    data : [
        {"id":1,"anon_id":"","event_name":"JOURNEY1","event_answer":"TRUE","created_at":"2024-12-03T19:07:13.696Z",
            "consumer_id":"ise+webhook19@trustwillow.com"},
        {"id":2,"anon_id":"323e1aab-2e67-407c-85af-93193abc5516", "event_name":"JOURNEY1","event_answer":"TRUE","created_at":"2024-12-03T19:07:14.106Z","consumer_id":"ise+webhook18@trustwillow.com"},
        {"id":3,"anon_id":"d87c1e14-1f39-4ab4-9ae7-d3f5adec0b1e","event_name":"JOURNEY2","event_answer":"TRUE","created_at":"2024-12-03T19:18:11.505Z","consumer_id":"ise+webhook17@trustwillow.com"}]
}

const devEventsAggregated = {
    data : {"employed":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],
        "finGoals":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"state":[{"event_count":"2","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"3","event_date":"2024-12-12T00:00:00.000Z"}],
        "email":[{"event_count":"1","event_date":"2024-10-3T00:00:00.000Z"},{"event_count":"3","event_date":"2024-12-12T00:00:00.000Z"}],"referral":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"clientAcquisitionSurveyStart":[{"event_count":"2","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"4","event_date":"2024-12-12T00:00:00.000Z"}],"advisorType":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"clientAcquisitionSurveyCaptureFirstName":[{"event_count":"2","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"maritalStatus":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"Retired":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"numChildren":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"businessOwn":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"workingWithAdvisor":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"rangeAssets":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"whyAdvisor":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"interests":[{"event_count":"1","event_date":"2024-12-13T00:00:00.000Z"},{"event_count":"2","event_date":"2024-12-12T00:00:00.000Z"}],"JOURNEY2":[{"event_count":"1","event_date":"2024-12-12T00:00:00.000Z"}],"phone":[{"event_count":"1","event_date":"2024-12-12T00:00:00.000Z"}]}
}

const devConsumers = {
    data : [
        {"id":2,"anon_id":"e9636723-a119-4d33-b41b-a3789a3b171e","email":"ise+webhook14@trustwillow.com","phone":"","utm_campaign":"","utm_medium":"","utm_term":"","utm_source":"","utm_content":""},{"id":34,"anon_id":"14c69163-403d-49e2-b186-fa3f91f1acd8","email":"ise+webhook15@trustwillow.com","phone":"","utm_campaign":"","utm_medium":"","utm_term":"","utm_source":"","utm_content":""},{"id":68,"anon_id":"323e1aab-2e67-407c-85af-93193abc5516","email":"ise+webhook18@trustwillow.com","phone":"","utm_campaign":"","utm_medium":"","utm_term":"","utm_source":"","utm_content":""},{"id":69,"anon_id":"","email":"ise+webhook19@trustwillow.com","phone":"","utm_campaign":"","utm_medium":"","utm_term":"","utm_source":"","utm_content":""},{"id":67,"anon_id":"d2b81ceb-6c7c-4fc6-99f0-39d409fdc066","email":"ise+webhook17@trustwillow.com","phone":"(901) 518-5080","utm_campaign":"","utm_medium":"","utm_term":"","utm_source":"","utm_content":""}]

}

const devUTMS = {
    data : {
        "utm_content" : ['content1, content2'],
        "utm_term" : ['term1', 'term2'],
        "utm_campaign": ['campaign1', 'campaign2'],
        "utm_source" : ['source1', 'source2'],
        "utm_medium": ['medium1', 'medium2']
    }
}
export const tryGetConsumers = async () => {
    const data = await axiosReq(`api/consumers`, "GET", {}, devConsumers);
    return data.data

}

export const tryGetEventsAggregated = async (start,end,dateType ) => {
    const data =  await axiosReq(`api/events-aggregated?start=${start}&end=${end}&dateType=${dateType}`, "GET", {}, devEventsAggregated);
    return data.data

}

export const tryGetUTMS = async () => {
    const data =  await axiosReq(`api/get-utms`, "GET", {}, devUTMS);
    return data.data[0]
}

export const tryGetEvents = () => {
    const data =  axiosReq(`api/events`, "GET", {}, devEvents);
    return data.data

}
