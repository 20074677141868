import {axiosReq} from "../utils/axios";


const devDashboardData = {
    data : [
        {
            "report_name": "FunnelReport1",
            "report_id":1,
            "created" : "2024-12-05",
            "modified": "2024-12-05",
            "report_type":"Funnel"
        },
        {
            "report_name": "GraphReport1",
            "report_id":2,
            "created" : "2024-12-05",
            "modified": "2024-12-05",
            "report_type":"Graph"
        },
        {
            "report_name": "TableReport1",
            "report_id":3,
            "created" : "2024-12-05",
            "modified": "2024-12-05",
            "report_type":"Table"
        }
    ]
}

const devFunnelReport = {
    data : [{id: 1, "event_name":"JOURNEY1","user_count":"2",
    "utm_source": "Google,StackAdapt"},{id: 2, "event_name":"JOURNEY2","user_count":"1", "utm_source": "Google,StackAdapt"}]
}

const devReport = {
    data : {"report_id":1,
        "report_type":"Graph",
        "display_type":"line",
        "start_date":"2024-05-24T00:00:00.000Z",
        "end_date":"2024-12-08T00:00:00.000Z",
        "event_names":["JOURNEY1"],
        "frequency":"day",
        "report_name":"DailySignUps",
        "created":"2024-12-09T00:00:00.000Z",
        "modified":"2024-12-09T00:00:00.000Z"}
}
export const tryGetEventsAggregated = (start = '', end = '', date_type = '') => {
    if (start !== '' && end !== '') {
        const data = axiosReq(`api/events/aggregated`, "GET", {}, {data: {}});
        return data.data

    } else {
        const data = axiosReq(`api/events/aggregated?start=${start}&end=${end}&dateType=${date_type}`, "GET", {}, {data: {}});
        return data.data
    }

}

export const tryGetEventNames = () => {
    return axiosReq(`api/events/get_all_event_name`, "GET", {}, {data : {}});

}

export const tryGetAllReports = async () => {
    const data = await axiosReq(`api/reports`, "GET", {}, devDashboardData);
    return data.data

}

export const tryGetReport = async (id) => {
    const data = await axiosReq(`api/reports/${id}`, "GET", {}, devReport);
    return data.data
}
export const tryGetGraphReport = async (id) => {
    const data = await axiosReq(`api/reports/${id}/Graph`, "GET", {}, {data : {}});
    return data.data
}

export const tryGetFunnelReport = async(id) => {
    const data = await axiosReq(`api/reports/{id}/Funnel`, "GET", {}, devFunnelReport);
    return data.data
}

export const tryCreateReport = async(json) => {
    const data = await axiosReq(`api/reports`, "POST", json, {data : {}});
    return data.data

}

export const trySaveReport = async(id, json) => {
    const data = await axiosReq(`api/reports/${id}`, "PUT", json, {data : {}});
    return data.data
}


export const tryDeleteReport = async(id) => {
    const data = await axiosReq(`api/reports/${id}`, "DELETE", {}, {data : {}});

}




