import React from "react";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {StyledTable, StyledTableCell, TableContainerStyled} from "./summary-report";

export default function Total(props) {
    const rows = props.rows
    const name = props.name
    const events = props.events
    console.log("rows", rows)
    console.log("events", events)
    if (rows.length <= 0) {
        return <h3>No data for the given timeframe or data input</h3>
    }
    return <TableContainerStyled>
        {/*  "Total Paid Data" Table       */}
        <StyledTable sx={{minWidth:600}} aria-label="simple table" className={'totalTable'}>
            <TableHead>
                <TableRow className={'dateRow'}>
                    <StyledTableCell className={"cellName"}>{name}</StyledTableCell>
                    {rows.map((row, index) => (

                        <StyledTableCell component="th" scope="row" key={"date" + index}>
                            {`${new Date(row.event_date).getMonth() + 1}/${new Date(row.event_date).getDate()}/${new Date(row.event_date).getFullYear()}`}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            ${Number(row.spend).toLocaleString()}
                        </StyledTableCell>
                    })}

                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            {Number(row.impressions).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Clicks</StyledTableCell>
                    {rows.map((row, index) => {
                        return <StyledTableCell key={"spend" + index}>
                            {Number(row.clicks).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'} >Survey Starts</StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["clientAcquisitionSurveyStart"] && events["clientAcquisitionSurveyStart"][index]) {
                            e = events["clientAcquisitionSurveyStart"][index].event_count
                        }
                        return <StyledTableCell key={"survey" + index}>
                            {Number(e).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" className={'rowName'} >Email Captures</StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        console.log("Events email", events["email"])
                        if (events["email"] && events["email"][index]) {
                            e = events["email"][index].event_count
                        }
                        return <StyledTableCell key={"email" + index}>
                            {Number(e).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>


                <TableRow>
                    <StyledTableCell align="left" className={'rowName'} >Asset Captures</StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["rangeAssets"] && events["rangeAssets"][index]) {
                            e = events["rangeAssets"][index].event_count
                        }
                        return <StyledTableCell key={"asset" + index}>
                            {Number(e).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>


                <TableRow>
                    <StyledTableCell align="left" className={'rowName'} >Phone Captures</StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["phone"] && events["phone"][index]) {
                            e = events["phone"][index].event_count
                        }
                        return <StyledTableCell key={"phone" + index}>
                            {Number(e).toLocaleString()}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Spend / Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        const impressionsPerClick = (row.spend / row.impressions).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${impressionsPerClick}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend / Clicks
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        const spendPerClick = (row.spend / row.clicks).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerClick}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                        Cost / Survey Start
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["clientAcquisitionSurveyStart"] && events["clientAcquisitionSurveyStart"][index]) {
                            e = events["clientAcquisitionSurveyStart"][index].event_count
                        }
                        const spendPerStart = (row.spend / e).toFixed(2).toLocaleString();
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerStart}
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                        Cost / Email Capture
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["email"] && events["email"][index]) {
                            e = events["email"][index].event_count
                        }
                        const spendPerEmail = (row.spend / e).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerEmail}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                        Cost / Assets
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["rangeAssets"] && events["rangeAssets"][index]) {
                            e = events["rangeAssets"][index].event_count
                        }
                        const spendPerAssets = (row.spend / e).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerAssets}
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" className={'rowName'} >
                        Cost / Phone Capture
                    </StyledTableCell>
                    {rows.map((row, index) => {
                        let e = 0;
                        if (events["phone"] && events["phone"][index]) {
                            e = events["phone"][index].event_count
                        }
                        const spendPerPhone = (row.spend / e).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            ${spendPerPhone}
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}># Clicks / # Impressions</StyledTableCell>
                    {rows.map((row, index) => {
                        const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>CTR </StyledTableCell>
                    {rows.map((row, index) => {
                        const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>
                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Email Capture Rate</StyledTableCell>
                    {rows.map((row, index) => {
                        let step1 = 0;
                        if (events["clientAcquisitionSurveyStart"] && events["clientAcquisitionSurveyStart"][index]) {
                            step1 = events["clientAcquisitionSurveyStart"][index].event_count
                        }

                        let step2 = 0;
                        if (events["email"] && events["email"][index]) {
                            step2 = events["email"][index].event_count
                        }
                        const clicksToImpressions = ((step2/step1) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Asset Capture Rate </StyledTableCell>
                    {rows.map((row, index) => {
                        let step1 = 0;
                        if (events["email"] && events["email"][index]) {
                            step1 = events["email"][index].event_count
                        }

                        let step2 = 0;
                        if (events["rangeAssets"] && events["rangeAssets"][index]) {
                            step2 = events["rangeAssets"][index].event_count
                        }
                        const clicksToImpressions = ((step2 / step1) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>

                <TableRow>
                    <StyledTableCell align="left" className={'rowName'}>Phone Capture Rate </StyledTableCell>
                    {rows.map((row, index) => {
                        let step1 = 0;
                        if (events["rangeAssets"] && events["rangeAssets"][index]) {
                            step1 = events["rangeAssets"][index].event_count
                        }

                        let step2 = 0;
                        if (events["phone"] && events["phone"][index]) {
                            step2 = events["phone"][index].event_count
                        }
                        const clicksToImpressions = ((step2 / step1) * 100).toFixed(2).toLocaleString()
                        return <StyledTableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </StyledTableCell>
                    })}
                </TableRow>
            </TableBody>
        </StyledTable>



    </TableContainerStyled>
}