import {useEffect, useState} from "react";
import React from "react"
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CanvasJSReact from '@canvasjs/react-charts';
import {Button, TextField} from "@mui/material";
import {tryCreateReport, tryGetFunnelReport, tryGetReport, trySaveReport} from "../../middleware/getReportData";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {DateTime} from "luxon";
import {tryGetEvents} from "../../middleware/consumerData";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function FunnelReport (props) {
    const id = props.id
    const [availableEvents, setAvailableEvents] = useState([])
    const [startDate, setStartDate] = useState(DateTime.local())
    const [endDate, setEndDate] = useState(DateTime.local())
    const [displayType, setDisplayType] = useState("")
    const [frequency, setFrequency] = useState("")
    const [reportType, setReportType] = useState("")
    const [eventNames, setEventNames] = useState([])
    const [events, setEvents] = useState([])
    const [reportName, setReportName] = useState("")
    const [locale, setLocale] = useState('en-us');
    const [isNew, setIsNew] = useState(false)


    const stepsToDataPoints = () => {
        const stepsList = []
        events.forEach((e) => {
            stepsList.push({label: e.event_name, y: Number(e.user_count) });
        });
        return stepsList
    }
    const options = {
        title: {
            text: reportName
        },
        data: [
            {
                type: displayType,
                dataPoints: stepsToDataPoints()
            }
        ]
    }

    const handleEventName = (event) => {
        const {
            target: { value },
        } = event;
        setEventNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const init = async () => {
        const report = await tryGetReport(id)
        setIsNew(!report)
        setFrequency(report?.frequency ? report.frequency : "")
        setDisplayType(report?.display_type ? report.display_type : "")
        setStartDate(report?.start_date ? DateTime.fromISO(report.start_date) : "")
        setEndDate(report?.end_date ? DateTime.fromISO(report.end_date) : "")
        setReportType(report?.report_type ? report.report_type : "")
        setEventNames(report?.event_names ? report.event_names : [])
        setReportName(report?.report_name ? report.report_name : "")
        const events = await tryGetEvents()
        setAvailableEvents([...new Set(events.map(e => e.event_name))])

    }

    const updateDateRange = async () => {
        await trySaveReport(id)
    }

    const save = async () => {
        const json = {
            "display_type" : displayType,
            "start_date" : startDate,
            "end_date": endDate,
            "frequency": frequency,
            "report_type": reportType,
            "event_names": eventNames,
            "report_name":reportName,
        }
       if (isNew) {
           await tryCreateReport(json)
           setIsNew(false)
       }
       await trySaveReport(id, json)
    }

    useEffect(() => {
        init();
    },[])

    const update = async () => {
        const json = {
            "display_type" : displayType,
            "start_date" : startDate,
            "end_date": endDate,
            "frequency": frequency,
            "report_type": reportType,
            "event_names": eventNames,
            "report_name":reportName,
        }
        await trySaveReport(id, json)
        const funnel = await tryGetFunnelReport(id)
        setEvents(funnel)
    }
    useEffect(() => {
        update();
    },[frequency, startDate, endDate, eventNames, displayType])

    return (
        <div style={{padding: "30px"}}>
            <TextField
                id="outlined-basic" label="Report Name" variant="outlined"
                       value={reportName}
                       onChange={(e) => setReportName(e.target.value)} />
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel>Events</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={eventNames}
                onChange={handleEventName}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {availableEvents.map((event) => (
                    <MenuItem
                        key={event}
                        value={event}
                    >
                        {event}
                    </MenuItem>
                ))}
            </Select>


        </FormControl>
            <FormControl>
            <InputLabel>Display Type</InputLabel>
            <Select
                sx={{m: 1, minWidth: 120}}
                value={displayType}
                onChange={(e) => setDisplayType(e.target.value)}
                MenuProps={MenuProps}
            >
                {["line", "column", "doughnut", "splineArea"].map((event) => (
                    <MenuItem
                        key={event}
                        value={event}
                    >
                        {event}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            <FormControl>
            <InputLabel>Frequency</InputLabel>
            <Select
                sx={{m: 1, minWidth: 120}}
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                MenuProps={MenuProps}
            >
                {["Daily", "Weekly", "Monthly"].map((event) => (
                    <MenuItem
                        key={event}
                        value={event}
                    >
                        {event}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
                <DatePicker
                    label="Start"
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                />
                <DatePicker
                    label="End"
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                />
                <Button onClick={() => updateDateRange()}>Filter by date range</Button>

            </LocalizationProvider>
            <Button onClick={() => save()}>Save</Button>
        <CanvasJSChart options = {options} />
        </div>
    )

}