import { HotTable } from '@handsontable/react';
import React, {useEffect, useState} from "react";
import {tryGetConsumers} from "../../middleware/consumerData";
import Paper from "@mui/material/Paper";
import {DataGrid} from "@mui/x-data-grid";
import {tryCreateReport, tryGetFunnelReport, trySaveReport} from "../../middleware/getReportData";
import {Button, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {DateTime} from "luxon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function TableReport() {
    const [availableEvents, setAvailableEvents] = useState([])
    const [startDate, setStartDate] = useState(DateTime.local())
    const [endDate, setEndDate] = useState(DateTime.local())
    const [displayType, setDisplayType] = useState("")
    const [frequency, setFrequency] = useState("")
    const [reportType, setReportType] = useState("")
    const [eventNames, setEventNames] = useState([])
    const [events, setEvents] = useState([])
    const [reportName, setReportName] = useState("")
    const [locale, setLocale] = useState('en-us');
    const [isNew, setIsNew] = useState(false)
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'event_name', headerName: 'Event Name', flex:1},
        { field: 'user_count', headerName: 'User Count', flex:1},
        // { field: 'utm_campaign', headerName: 'UTM Campaign', flex:1 },
        // { field: 'utm_medium', headerName: 'UTM Medium', flex:1},
        // { field: 'utm_term', headerName: 'UTM Term', flex:1 },
        // { field: 'utm_content', headerName: 'UTM Content', flex:1},
        { field: 'utm_source', headerName: 'UTM Source', flex:1 }
    ];
    const paginationModel = { page: 0, pageSize: 5 };
    useEffect(() => {
        init();
    },[])

    const init = async () => {
        const funnel = await tryGetFunnelReport(3)
        setRows(funnel)
    }
    if (rows.length === 0) {
        return <h1>No data</h1>
    }

    const updateDateRange = async () => {
        await trySaveReport(id)
    }

    const handleEventName = (event) => {
        const {
            target: { value },
        } = event;
        setEventNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const save = async () => {
        const json = {
            "display_type" : displayType,
            "start_date" : startDate,
            "end_date": endDate,
            "frequency": frequency,
            "report_type": reportType,
            "event_names": eventNames,
            "report_name":reportName,
        }
        if (isNew) {
            await tryCreateReport(json)
            setIsNew(false)
        }
        await trySaveReport(id, json)
    }





    return (
        <div style={{padding: "30px"}}>
            <TextField
                id="outlined-basic" label="Report Name" variant="outlined"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)} />
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>Events</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={eventNames}
                    onChange={handleEventName}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {availableEvents.map((event) => (
                        <MenuItem
                            key={event}
                            value={event}
                        >
                            {event}
                        </MenuItem>
                    ))}
                </Select>


            </FormControl>
            {/*<FormControl>*/}
            {/*    <InputLabel>Display Type</InputLabel>*/}
            {/*    <Select*/}
            {/*        sx={{m: 1, minWidth: 120}}*/}
            {/*        value={displayType}*/}
            {/*        onChange={(e) => setDisplayType(e.target.value)}*/}
            {/*        MenuProps={MenuProps}*/}
            {/*    >*/}
            {/*        {["line", "column", "doughnut", "splineArea"].map((event) => (*/}
            {/*            <MenuItem*/}
            {/*                key={event}*/}
            {/*                value={event}*/}
            {/*            >*/}
            {/*                {event}*/}
            {/*            </MenuItem>*/}
            {/*        ))}*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <FormControl>
                <InputLabel>Frequency</InputLabel>
                <Select
                    sx={{m: 1, minWidth: 120}}
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    MenuProps={MenuProps}
                >
                    {["Daily", "Weekly", "Monthly"].map((event) => (
                        <MenuItem
                            key={event}
                            value={event}
                        >
                            {event}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
                <DatePicker
                    label="Start"
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                />
                <DatePicker
                    label="End"
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                />
                <Button onClick={() => updateDateRange()}>Filter by date range</Button>

            </LocalizationProvider>
            <Button onClick={() => save()}>Save</Button>
        <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
            />
        </Paper>
            </div>
            )

}