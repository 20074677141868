import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {tryGetEvents} from "../../middleware/consumerData";

export default function EventsTable() {
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'anon_id', headerName: 'Anon_ID', flex:1 },
        { field: 'event_name', headerName: 'Event Name', flex:1 },
        { field: 'event_answer', headerName: 'Event Response', flex:1 },
        { field: 'utm_campaign', headerName: 'UTM Campaign', flex:1 },
        { field: 'utm_medium', headerName: 'UTM Medium', flex:1},
        { field: 'utm_term', headerName: 'UTM Term', flex:1 },
        { field: 'utm_content', headerName: 'UTM Content', flex:1},
        { field: 'utm_source', headerName: 'UTM Source', flex:1 },
        { field: 'event_date', headerName: 'Timestamp', flex:1},
    ];
    const paginationModel = { page: 0, pageSize: 5 };
    useEffect(() => {
        init();
    },[])

    const init = async () => {
        const data = await tryGetEvents();
        setRows(data)
    }
    if (rows.length === 0) {
        return <h1>No data</h1>
    }
    return (
        <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
            />
        </Paper>)
}